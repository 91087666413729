import axios from "axios";
import React, { useEffect, useState } from "react";
import requests from "../../utils/Requests";
import { AgGridReact } from "ag-grid-react";
import { Alert, Spinner } from "reactstrap";
const PaymentHistory = (props) => {
  const { agentId } = props;
  const [isLoading, setisLoading] = useState(false);
  const [rowData, setrowData] = useState([""]);
  useEffect(() => {
    getTransactionData();
  }, [agentId]);

  const getTransactionData = async () => {
    setisLoading(true);
    await axios
      .post(requests.getTopupList, {
        agencyId: agentId,
        secretKey: requests.apiKey,
      })
      .then((res) => {
        console.log(res.data);
        setRecord(res.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setisLoading(false);
      });
  };
  const setRecord = (tempData) => {
    const tempRecord = tempData.map(
      ({
        tranCreditLimit,
        trnDate,
        paymentType,
        trnRemarks,
        tranCreditAmount,
        tranCurrentBanlance,
      }) => ({
        tranCreditLimit: tranCreditLimit,
        trnDate: trnDate ? new Date(trnDate).toDateString() : "",
        paymentType: paymentType,
        trnRemarks: trnRemarks,
        tranCreditAmount: tranCreditAmount,
        tranCurrentBanlance: tranCurrentBanlance,
      })
    );
    setrowData(tempRecord);
  };

  const columnDefs = [
    { headerName: "Credit Limit", field: "tranCreditLimit" },
    { headerName: "Updated On", field: "trnDate" },
    { headerName: "Payment Type", field: "paymentType" },
    { headerName: "Remark", field: "trnRemarks" },

    { headerName: "Credited Amount", field: "tranCreditAmount" },
    { headerName: "Current Balance", field: "tranCurrentBanlance" },
  ];
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    minWidth: 100,
  };

  return (
    <div
      style={{
        marginTop: "30px",
      }}
    >
      {isLoading == true ? (
        <>
          {" "}
          <Alert color="primary">
            Your Data Is Loading Please Wait <Spinner color="light" />
          </Alert>
        </>
      ) : null}

      <div className="ag-theme-alpine" style={{ height: "500px" }}>
        <AgGridReact
          id="Inventory"
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          // onGridReady={onGridReady}
          pagination={true}
          // paginationPageSize={5}
          // paginationAutoPageSize={true}
        ></AgGridReact>
      </div>
    </div>
  );
};

export default PaymentHistory;
