/** @format */

import React, { Component } from "react";
import AboutUs from "../pages/aboute_us";
import Attractions from "../pages/attractions";
import Contactpage from "../pages/contact_page";
import DatedTktPayment from "../pages/DatedTktPayment";
import Expopage from "../pages/Expo/expo-page";
import ApiTicketConfirmation from "../pages/global-components/ApiTicketConfirmation";
import Billdesign from "../pages/global-components/billdesignpage";
import PaymentPage from "../pages/global-components/PaymentPage";
import TestReduxForm from "../pages/global-components/TestReduxForm";
import Home from "../pages/home";
import Loginpage from "../pages/Loginpage";
import Paymentconfirmation from "../pages/PaymentConfirmation";
import Privecypolicy from "../pages/Privecypolicy";
import Termsandconditions from "../pages/Termsandcondtions";
import TourPackagesDetail from "../pages/tourpackages_detail";
import TourPackDetail from "../pages/tourpack_detail";
import TourpackdayPackList from "../pages/tour_pack_day_detail";
import TourdayPackList from "../pages/tour_pack_day_detail";
import Userprofile from "../pages/use-profile/Userprofile";
import VisaBooingForm from "../pages/visaservises/VisaBookingForm";
import Visapage from "../pages/visa_page";
import AttractionDetails from "../tour-pack-detail/attraction_details";
import CartDetails from "../tour-pack-detail/cart_details";
import ComboDetails from "../tour-pack-detail/combo_details";
import ComboPack from "../tour-pack-detail/combo_pack";
import SslTest from "../SslTest";
import PaymentFailure from "../pages/PaymentFailure";
import BookingConfirm from "../pages/global-components/BookingConfirm";
import BookingFailiure from "../pages/global-components/BookingFailiure";
import MofConfirmation from "../pages/global-components/MofConfirmation";
import GenerateTickets from "../pages/global-components/GenerateTickets";
const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
  },
  {
    path: "/about-us",
    component: AboutUs,
  },
  {
    path: "/attraction",
    component: Attractions,
  },

  {
    path: "/combo-pack",
    component: ComboPack,
  },

  {
    path: "/combo-pack-details/:id",
    component: ComboDetails,
  },
  // {
  //   path: "/abc/:attId",
  //   component: AttractionDetails,
  // },
  // {
  //     path: "/pakages/honeymoon",
  //     component: Honeymoonpack,

  // },
  {
    path: "/tour-packages",
    component: TourpackdayPackList,
  },
  {
    path: "/contact-page",
    component: Contactpage,
  },
  {
    path: "/tour-list",
    component: TourdayPackList,
  },
  {
    path: "/dated-ticket-payment",
    component: DatedTktPayment,
  },
  {
    path: "/tour-pack-details/:id",
    component: TourPackDetail,
  },
  {
    path: "/tour-day-pack-details/:id",
    component: TourPackagesDetail,
  },
  {
    path: "/login-page",
    component: Loginpage,
  },
  {
    path: "/online-payment-port",
    component: PaymentPage,
  },

  {
    path: "/user-profile-page",
    component: Userprofile,
  },
  {
    path: "/api-tkt-confirmation-page",
    component: ApiTicketConfirmation,
  },
  {
    path: "/user-cart-details",
    component: CartDetails,
  },
  {
    path: "/dubai-expo-2020",
    component: Expopage,
  },
  {
    path: "/visa-services",
    component: Visapage,
  },
  {
    path: "/:tktType/payment-status",
    component: BookingConfirm,
  },
  {
    path: "/billdesign",
    component: Billdesign,
  },
  {
    path: "/terms-and-contitions",
    component: Termsandconditions,
  },
  {
    path: "/Privecy-policy",
    component: Privecypolicy,
  },
  {
    path: "/visa-booking-service",
    component: VisaBooingForm,
  },
  {
    path: "/:tktType/booking-failure/:urlId",
    component: BookingFailiure,
  },
  {
    path: "/mof-ticket-confirmation",
    component: MofConfirmation,
  },

  {
    path: "/gerate-existing-tickets/:id",
    component: GenerateTickets,
  },
];

export default routes;
