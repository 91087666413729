import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";
import Visacommon from "./visaservises/Visacommon";

const Visapage = () => {
  return (
    <>
      <TopNav />
      <CommonBanner title="Our Visa Services" imgUrl="visa_Banner.jpg" />
      <Visacommon />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Visapage;
