import TourpackDayDetail from "../tour-pack-detail/tour_pack_detail";

const TourPackagesDetail = () => {
  return (
    <>
      <TourpackDayDetail />
    </>
  );
};

export default TourPackagesDetail;
