const About = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <div className="about-section pd-top-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="section-title mb-lg-0">
                <h2 className="title">
                  Lets Go Travel <br /> with Us
                </h2>
                <p>
                  {" "}
                  Parmar Tourism comprise of professionals in the tourism
                  industry. It was a big challenge to establish a company but
                  through hard work, dedication and strong determination, we
                  became one of the leading tour operators in the UAE. In this
                  rapidly growing industry, we aim to utilize the current trend
                  to infiltrate the market and intend to provide competitive
                  travel and adventure packages to tourists. Our services will
                  be of extremely high quality, informative and modified to suit
                  the clients’ needs. Our goal is to provide tailor-made
                  packages at a competitive cost in the market. We will ensure
                  that guest will have plenty of packages to choose from and
                  fits their budget.
                </p>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-2">
              <div className="thumb about-section-right-thumb">
                <img
                  src={publicUrl + "assets/img/others/about2.jpeg"}
                  alt="parmartours"
                />
                <img
                  className="about-absolute-thumb"
                  src={publicUrl + "assets/img/others/about1.jpeg"}
                  alt="parmartours"
                />
              </div>
            </div>
          </div>
          <br />
          <p>
            Our services will be extremely high quality, comfortable,
            informative and modified to the clients’ needs. Our goal is to make
            the right information available to the right target customers. We
            will ensure that our prices take into consideration peoples'
            budgets, that these people appreciate the service(s).it is important
            to recognize that we do not intend to just take individuals on
            sightseeing pleasure trip, but also to ensure that they appreciate
            nature through informative briefing on objects' origins. Hence we
            need to connect the right people in the right place at the right
            time if we are to guarantee best possible growth. We propose to
            develop our team so that Our People Can Grow As The Company Grows -
            A Mutually Valuable Relationship.
          </p>
          <br />
          <p>
            To be the best ground operator to one of the most worthwhile
            destination in the world, the UAE.{" "}
          </p>
          <p>
            To provide a journey worth to share the experience to guests by
            satisfying their needs and surpassing their expectations at a
            competitive cost in the market.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
