import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Field, Form, reduxForm } from "redux-form";
import { useState, useEffect } from "react";
import RenderField from "../../form-components/RenderField";
import { Label, Row, Col, Button, Alert, Spinner } from "reactstrap";
import axios from "axios";
import requests from "../../utils/Requests";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const Bookinglist = (props) => {
  useEffect(() => {
    //idForReport, loginUser
  }, []);
  const [isLoading, setisLoading] = useState(0);
  const { handleSubmit, pristine, reset, submitting } = props;

  const [filterType, setfilterType] = useState();
  const [rowData, setrowData] = useState([]);
  const getBookingDetails = (values) => {
    //getSalesReportForB2b
    setisLoading(1);
    // let agencyid = 0,
    //   agencyUserid = 0;
    // if (props.loginUser == 1) {
    //   agencyid = props.idForReport;
    // } else {
    //   agencyUserid = props.idForReport;
    // }

    const submitData = {
      startDate: values.startDate,
      endDate: values.endDate,
      agencyId: props.idForReport,
      filterType: filterType,
      bookingTravelDate: values.bookingTravelDate,
      getTicketsAlso: values.getTicketsAlso,
      secretKey: requests.apiKey,
    };

    console.log("Payment data", `${JSON.stringify(submitData, null, 2)}`);
    setrowData([]);
    axios
      .post(requests.getSalesReportForB2b, submitData)
      .then((response) => {
        console.log("organizedData", response.data);
        setRecordForTable(response.data);
        setisLoading(0);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setRecordForTable = (record) => {
    const organizedData = record.map((item) => ({
      bookingId: item.bookingId,
      portel_userName: item.agencyName,
      agentRefNumber: item.agentRefNumber,
      bookingRefNumber: item.bookingRefNumber,
      attractionName: item.attractionName,
      paxName: item.paxName,
      contactNumber: item.contactNumber,
      no_Adult: item.nofAdult,
      no_Child: item.nofChild,
      AdultPrice: item.salesAmountAdult,
      ChildPrice: item.salesAmountChild,
      Total: item.bookTotal,
      bookingDate: new Date(item.bookingDate).toLocaleDateString(),
      bookTravellDate: new Date(item.bookingTravelDate).toLocaleDateString(),
      InvoiceNumber: item.invoiceNumber,
      bookPaymentMode: paymode(item.bookPaymentMode),
      pdfFileName: item.pdfFileName,
    }));
    console.log("organizedData", organizedData);
    setrowData(organizedData);
  };

  const paymode = (bookPaymentMode) => {
    if (bookPaymentMode == 1) {
      return "Online";
    } else {
      return "Credit";
    }
  };

  const columnDefs = [
    { headerName: "Booking Id", field: "bookingId" },
    { headerName: "Portel User Name", field: "portel_userName" },
    { headerName: "Agent Ref Number ", field: "agentRefNumber" },
    { headerName: "Book Ref Number", field: "bookingRefNumber" },
    { headerName: "Attraction Name", field: "attractionName" },
    { headerName: "Pax Name", field: "paxName" },
    { headerName: "Contact Number", field: "contactNumber" },
    { headerName: "no.Adult", field: "no_Adult" },
    { headerName: "no.Child", field: "no_Child" },
    { headerName: "Adult Price", field: "AdultPrice" },
    { headerName: "Child Price", field: "ChildPrice" },
    { headerName: "Total", field: "Total" },
    { headerName: "Book Date", field: "bookingDate" },
    { headerName: "Travel Date", field: "bookTravellDate" },
    { headerName: "Invoice", field: "InvoiceNumber" },
    { headerName: "PaymentMode", field: "bookPaymentMode" },
    { headerName: "Pdf File Name", field: "pdfFileName" },
    {
      headerName: "Actions",
      filter: false,
      field: "ticketNumber",
      cellRendererFramework: (params) => (
        <div>
          <Button color="primary" onClick={() => actionButton(params)}>
            <i class="fa fa-download" aria-hidden="true"></i>
          </Button>
        </div>
      ),
    },
  ];
  // const columnDefs = [
  //   { headerName: "Ticket Number", field: "ticketNumber" },
  //   { headerName: "Ref Number", field: "agentRefNumber" },
  //   { headerName: "Booking RefNo", field: "bookingRefNumber" },

  //   { headerName: "Invoivce ", field: "invoiceNumber" },
  //   { headerName: "Transaction", field: "transactionId" },
  //   { headerName: "Attraction Name", field: "attractionName" },
  //   { headerName: "Pax Name", field: "paxName" },
  //   { headerName: "Number", field: "contactNumber" },
  //   { headerName: "Total Amount", field: "bookTotal" },
  //   // { headerName: "Sales Amount", field: "salesAmountAdult" },
  //   { headerName: "Sales Date", field: "bookingDate" },
  //   { headerName: "Travel Date", field: "bookingTravelDate" },
  //   { headerName: "PaymentMode", field: "bookPaymentMode" },
  //   {
  //     headerName: "Actions",
  //     filter: false,
  //     field: "ticketNumber",
  //     cellRendererFramework: (params) => (
  //       <div>
  //         <Button color="primary" onClick={() => actionButton(params)}>
  //           <i class="fa fa-download" aria-hidden="true"></i>
  //         </Button>
  //       </div>
  //     ),
  //   },
  // ];
  const actionButton = (params) => {
    // console.log(params);
    const fileURL =
      // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
      " https://www.b2b.parmartours.com/filestorage/" + params.data.pdfFileName;
    const filename = "e-Ticket.pdf";
    download_file(fileURL, filename);
    //  alert(`${params.data.tourPackageId} `);
    //{`tour-pack-details/${alltour.attractionsId}`}"/attraction/attraction-creation"
  };

  /* Helper function */

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(getBookingDetails.bind(this))}>
        <h4>Select Filter Type</h4>
        <Row>
          <Col sm={2}>
            <Field
              style={{
                marginTop: "20px",
              }}
              name="filterType"
              component="input"
              type="radio"
              value="1"
              onChange={(e) => setfilterType(e.target.value)}
            />{" "}
            Filter By Booking Date
          </Col>
          <Col sm={2}>
            <Field
              style={{
                marginTop: "20px",
              }}
              name="filterType"
              component="input"
              type="radio"
              value="2"
              onChange={(e) => setfilterType(e.target.value)}
            />{" "}
            Filter By Travel Date
          </Col>
        </Row>
        <Row>
          {filterType === "1" && (
            <>
              <Col sm={2}>
                <Field
                  name="startDate"
                  type="date"
                  label="From"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col sm={2}>
                <Field
                  name="endDate"
                  type="date"
                  label="To"
                  component={RenderField.RenderTextField}
                />
              </Col>
            </>
          )}
          {filterType === "2" && (
            <Col sm={2}>
              <Field
                name="bookingTravelDate"
                type="date"
                label="Travel Date"
                component={RenderField.RenderTextField}
              />
            </Col>
          )}

          <Col sm={2}>
            <br />
            <Field
              style={{
                marginTop: "20px",
              }}
              name="getTicketsAlso"
              component="input"
              type="radio"
              value="true"
              //  onChange={(e) => settktType(e.target.value)}
            />{" "}
            With tickets
          </Col>
          <Col sm={2}>
            <br />
            <Field
              style={{
                marginTop: "20px",
              }}
              name="getTicketsAlso"
              component="input"
              type="radio"
              value="false"
              //  onChange={(e) => settktType(e.target.value)}
            />{" "}
            Without tickets
          </Col>
        </Row>
        <br />
        <div className="float-center">
          <Button color="primary" disabled={submitting}>
            Submit
          </Button>{" "}
          &nbsp; &nbsp;&nbsp;
        </div>
      </Form>
      <br />

      {isLoading == 1 ? (
        <>
          {" "}
          <Alert color="primary">
            Your Data Is Loading Please Wait <Spinner color="light" />
          </Alert>
        </>
      ) : null}
      <br />
      <div className="">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <Button
            color="primary"
            onClick={(e) => exportToCSV(rowData, "Agent_Sales_Report")}
          >
            Download CSV
          </Button>
          <AgGridReact
            id="Inventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            // paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};
export default reduxForm({
  form: "Bookinglist",
})(Bookinglist);
