import CommonBanner from "./common_banner";
import Footer from "./footer";
import Subscribe from "./subscribe";
import TopNav from "./top_nav";
const Billdesign = () => {

    return ( <>
    <TopNav/>
    <CommonBanner title ="Thank you"/>
    <div className="about-section pd-top-80">
        <div className="container"> 
        <h3 className="title">
                 Thank you for your booking , Jeyapal
                </h3>
           
            <p>A Confirmation email will be send you at with your complete booking details and e-Ticket</p>
            </div>
            </div>
           
   <Subscribe/>
   <Footer/>
    
    </> );
}
 
export default Billdesign;