import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";
import encryptStorage from "../../utils/Encryptstorage";
import { Row, Col } from "reactstrap";
const Tourorderlist = () => {
  let imagealt = "image";
  const history = useHistory();
  // const [isLoading, setLoading] = useState(true);
  const [LoggedIn, setLoggedIn] = useState();
  const [packprice, setpackprice] = useState();
  let agencyuser = 0;
  let agency = 0;

  const enl = encryptStorage.getItem("enl");
  let login = sessionStorage.getItem("isLoggedIn");

  useEffect(() => {
    // let b2b = sessionStorage.getItem("b2b");
    // let b2bUser = sessionStorage.getItem("b2bUser");

    if (login == "yes") {
      const userDetail = JSON.parse(enl);
      if (userDetail.b2b == true) {
        agency = userDetail.agency.agencyId;
        getAllTourList(userDetail.agency.agencyId, 0);
        setLoggedIn(1);
      } else if (userDetail.b2bUser == true) {
        //agencyUser.agencyUserId
        agencyuser = userDetail.agencyUser.agencyUserId;
        getAllTourList(0, userDetail.agencyUser.agencyUserId);
      } else {
        // setb2cUser(1);
        getAllTourList(0, 0);
        setLoggedIn(0);
      }
    } else {
      getAllTourList(0, 0);
      setLoggedIn(0);
    }
    // getAllTourList();
    getallparkgroup();
  }, []);

  const [tourlist, setTourlist] = useState([].slice(0, 1));
  const [allTours, setallTours] = useState([]);

  let attraction = { attractionId: 1 };
  const getAllTourList = async (b2bid, b2buserid) => {
    await axios
      .post(requests.getAttractionAll, {
        attractionId: 1,
        agencyId: b2bid,
        agencyUserId: b2buserid,
        currencyCode: "AED",
        platformId: 1,
      })
      .then((res) => {
        // console.log(
        //   "Response", res.data
        // )
        setallTours(res.data);
        setTourlist(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [parkGroup, setparkGroup] = useState([
    { value: "all-tours", name: "All Attraction" },
  ]);

  const getallparkgroup = async () => {
    await axios
      .post(requests.getallparkgroup, attraction)
      .then((res) => {
        const values = [...parkGroup];
        parkGroup.length = 0;
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].grpName,
            value: res.data[i].attractionGroupId,
          });
        }
        const filterArr = values.filter((item) => item.name);

        setparkGroup(filterArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterItems = (FilterList) => {
    if (FilterList === "all-tours") {
      const toursfilter = allTours.filter(
        (tour) => tour.attGroup !== FilterList
      );

      setTourlist(toursfilter);
    } else {
      const toursfilter = allTours.filter(
        (tour) => tour.attGroup === FilterList
      );

      setTourlist(toursfilter);
    }
    window.scrollTo(0, 0);
    //const newItem = tourlist.filter((tour) => tour.attGroup === FilterList);
    //setTourlist( toursfilter.filter((tour) => tour.attGroup === FilterList));
  };
  // if (isLoading) {
  //   <p>loading...</p>;
  // }
  const [search, setsearch] = useState("");
  const fulterAttraction = tourlist.filter((attract) => {
    return attract.attName.toLowerCase().includes(search.toLocaleLowerCase());
  });
  return (
    <>
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-8 order-lg-12">
              {/* Attraction */}

              <div className="widget-tour-list-search">
                <div className="search-form">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => setsearch(e.target.value)}
                    />
                  </div>
                  <button
                    className="submit-btn"
                    type="submit"
                    style={{
                      backgroundColor: "#1eb7f3",
                      color: "white",
                    }}
                  >
                    <i
                      className="ti-search"
                      style={{
                        color: "white",
                      }}
                    />{" "}
                    Search
                  </button>
                </div>
              </div>

              {fulterAttraction.map((alltour, index) => {
                return (
                  <>
                    <div key={index}>
                      <div className="tour-list-area">
                        <div className="single-destinations-list style-three">
                          <div className="thumb">
                            <Link
                              to={`tour-pack-details/${alltour.attractionsId}`}
                            >
                              <img
                                // src={publicUrl + "assets/img/destination-list/12.png"}
                                src={
                                  requests.imgpath + alltour.attThumbnailImage
                                }
                                alt="parmartours"
                              />
                            </Link>
                          </div>

                          <div className="details">
                            <h4 className="title">
                              {/* <Link to="/tour-details"></Link> */}
                              <Link
                                to={`tour-pack-details/${alltour.attractionsId}`}
                              >
                                {alltour.attName}
                              </Link>
                            </h4>

                            {alltour.attDescription ? (
                              <p className="content">
                                {" "}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: alltour.attDescription.substring(
                                      0,
                                      250
                                    ),
                                  }}
                                />
                              </p>
                            ) : null}

                            <div className="d-flex justify-content-end">
                              <div className="tp-price-meta d-inline-block">
                                <p>Location</p>
                                <h5>{alltour.attCity}</h5>
                              </div>
                              &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                              {login === "yes" && (
                                <>
                                  {" "}
                                  <div className="tp-price-meta d-inline-block">
                                    <p>Adult Price</p>
                                    <div style={{ display: "flex" }}>
                                      <h2>
                                        {alltour.adultPrice} &nbsp;&nbsp;&nbsp;
                                        <span> AED</span>
                                      </h2>
                                    </div>
                                  </div>
                                  &nbsp;&nbsp;&nbsp;
                                  <div className="tp-price-meta d-inline-block">
                                    <p>Child Price</p>

                                    <div style={{ display: "flex" }}>
                                      <h2>
                                        {alltour.childPrice} &nbsp;&nbsp;&nbsp;
                                        <span> AED</span>
                                      </h2>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div
                                className="tp-price-meta d-inline-block pull-right"
                                style={{ marginTop: "20px" }}
                              >
                                <Link
                                  className="btn btn-yellow"
                                  to={`tour-pack-details/${alltour.attractionsId}`}
                                >
                                  Book
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-xl-3 col-lg-4 order-lg-1">
              <div className="sidebar-area">
                <ul class="nav nav-tabs tp-tabs style-two">
                  {parkGroup.map((group, index) => (
                    <li class="nav-item" key={index}>
                      <a
                        class="nav-link "
                        data-toggle="tab"
                        href=""
                        value={group.value}
                        onClick={() => filterItems(group.value)}
                      >
                        {group.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tourorderlist;
