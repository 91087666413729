/** @format */

import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import requests from "../../utils/Requests";
const BannerImage = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagelinkfromserver =
    "https://parmartours.com:8443/filestorage/parmartour/images/";
  let imagealt = "image";
  useEffect(() => {
    grtBannerImage();
  }, []);

  const [bannerImageRightTop, setbannerImageRightTop] = useState();
  const [bannerImageMain, setbannerImageMain] = useState();
  const [bannerImageRightBottom, setbannerImageRightBottom] = useState();
  const grtBannerImage = () => {
    axios
      .post(requests.getWebsiteBannerImagesList, { websiteSettingsId: 0 })
      .then((response) => {
        let tempImage = response.data;
        let banimgMain = [],
          banimgRt = [],
          banimgRb = [];
        if (tempImage != "") {
          setbannerImageRightTop("");
          setbannerImageMain("");
          setbannerImageRightBottom("");
          for (let i = 0; i < tempImage.length; i++) {
            if (tempImage[i].category == "bannerImageMain") {
              banimgMain.push(tempImage[i]);
            }
            if (tempImage[i].category == "bannerImageRightTop") {
              banimgRt.push(tempImage[i]);
            }
            if (tempImage[i].category == "bannerImageRightBottom") {
              banimgRb.push(tempImage[i]);
            }
          }

          setbannerImageRightTop(banimgRt);
          setbannerImageMain(banimgMain);
          setbannerImageRightBottom(banimgRb);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {/* <div
        className="main-banner-area jarallax"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/img/banner/homebanner.jpg)",
        }}
      >
        <div className="content">
          <div className="container">
            <h2>Explore, Discover, Travel</h2>
            <h1>ADVENTURE</h1>
            <h1 className="shadow">ADVENTURE</h1>
            <div className="scroll-down">
              <a href="#main_search" className="text-center">
                <span />
                <i className="la la-long-arrow-down" />
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        {/* <Row>
          <Col sm={8} ClassName="padding-0">
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0011.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0012.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0013.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0014.png",

                  height: "430px",
                }}
              ></div>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0015.png",

                  height: "430px",
                }}
              ></div>
            </Carousel>
          </Col>
          <Col sm={4} ClassName="padding-0">
            <Col>
              <div
                className="main-banner-area jarallax"
                style={{
                  backgroundImage:
                    "url(" + imagelinkfromserver + "home0031.jpeg)",

                  height: "200px",
                }}
              ></div>
            </Col>
            <br />
            <Col>
              <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0021.jpg)",

                    height: "200px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0022.png)",

                    height: "200px",
                  }}
                ></div>
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" + imagelinkfromserver + "home0023.png)",

                    height: "200px",
                  }}
                ></div>
              </Carousel>
            </Col>
          </Col>
        </Row> */}

        <div className="row">
          <div className="col-lg-8 padding-0">
            <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
              {bannerImageMain &&
                bannerImageMain.map((image, index) => (
                  <div
                    className="main-banner-area jarallax"
                    style={{
                      backgroundImage:
                        "url(" + imagelinkfromserver + image.fileName + ")",

                      height: "530px",
                    }}
                  ></div>
                ))}
            </Carousel>
          </div>

          <div className="col-lg-4 padding-1">
            <Col>
              {bannerImageRightTop && (
                <div
                  className="main-banner-area jarallax"
                  style={{
                    backgroundImage:
                      "url(" +
                      imagelinkfromserver +
                      bannerImageRightTop[0].fileName +
                      ")",

                    height: "250px",
                  }}
                ></div>
              )}
            </Col>

            <br />
            <Col>
              <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
                {bannerImageRightBottom &&
                  bannerImageRightBottom.map((image, index) => (
                    <div
                      className="main-banner-area jarallax"
                      style={{
                        backgroundImage:
                          "url(" + imagelinkfromserver + image.fileName + ")",

                        height: "250px",
                      }}
                    ></div>
                  ))}
              </Carousel>
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerImage;
