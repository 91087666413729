import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";
import TourPackageList from "./tour-list/tour_package_list";

const TourpackdayPackList = () => {
    return (<>
    <TopNav/>
      <CommonBanner title="All Tour List"  imgUrl ="tourBanner.jpeg" />
    <TourPackageList/>
    <Subscribe/>
    <Footer/>
    </>  );
}
 
export default TourpackdayPackList;