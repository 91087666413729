/** @format */

import { reduxForm, Form, Field } from "redux-form";
import RenderField from "../form-components/RenderField";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import requests from "../utils/Requests";
import Swal from "sweetalert2";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TopNav from "../pages/global-components/top_nav";
import Subscribe from "../pages/global-components/subscribe";
import Footer from "../pages/global-components/footer";
import DynamicBanner from "../pages/global-components/dynamic_banner";
import Collapsible from "react-collapsible";
const validate = (values) => {
  const errors = {};
  if (!values.enqCusName) {
    errors.enqCusName = "Required ";
  }
  if (!values.enqEmail) {
    errors.enqEmail = "Required ";
  }
  if (!values.enqMobileNumber) {
    errors.enqMobileNumber = "Required";
  }
  if (!values.enqMessage) {
    errors.enqMessage = "Required";
  }

  return errors;
};

const TourpackDayDetail = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  let login = sessionStorage.getItem("isLoggedIn");
  const { id } = useParams();

  // const [getTourList, setgetTourList] = useState({
  //   attractionsId: 1,
  // });

  let attraction = { tourPackageId: id };

  useEffect(() => {
    window.scrollTo(0, 0);
    props.initialize({ attractionsId: id });
    getTourDeail();
  }, []);

  const [tourpack, setTourpack] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [dayActivity, setdayActivity] = useState([]);

  const getTourDeail = async () => {
    await axios
      .post(requests.getTourPackageSingle, attraction)
      .then((res) => {
        console.log(res.data);
        setTourpack(res.data);
        setdayActivity(res.data.packageDetail);

        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { handleSubmit, pristine, reset, submitting } = props;
  if (isLoading) {
    return (
      <>
        <p>Loading....</p>
      </>
    );
  }

  const setTourenq = (values, dispatch) => {
    // private int enquiryId;
    // private int tourPackageId;
    // private String tourPackageName;
    // private String enqCusName;
    // private String enqEmail;
    // private String enqMobileNumber;
    // private String enqMessage;
    // private Date enquiryDate;
    // private String enqFollowUpStatus;
    let currentdate = new Date();
    const enquiryData = {
      enquiryId: "",
      tourPackageId: id,
      tourPackageName: tourpack.tourName,
      enqCusName: values.enqCusName,
      enqEmail: values.enqEmail,
      enqMobileNumber: values.enqMobileNumber,
      enqMessage: values.enqMessage,
      enquiryDate: currentdate,
      enqFollowUpStatus: "",
    };

    // console.log(enquiryData); //setenquiry
    axios
      .post(requests.setenquiry, enquiryData)
      .then((response) => {
        // console.log("Response Data", response.data);
        Swal.fire({
          title: "Dear Customer", //'Good job!',
          text: "Our Agent Will Contact You Shortly", //'You clicked the button.',
          icon: "success", //'success'
        });
        dispatch(props.reset());
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <TopNav />
      <DynamicBanner
        imgUrl={requests.imgpath + tourpack.tourBannerImage}
        title={tourpack.tourName}
      />
      <div className="tour-details-area mg-top--70">
        <div className="tour-details-gallery">
          <div className="container-bg bg-dark-blue">
            <div className="container">
              <div className="gallery-filter-area row">
                <div className="gallery-sizer" />
                {/* gallery-item */}
                <div className="tp-gallery-item ">
                  <div className="tp-gallery-item-img">
                    <div className="thumbnails"></div>
                  </div>
                </div>
                {/* gallery-item */}

                {/* gallery-item */}

                {/* gallery-item */}

                {/* gallery-item */}

                {/* gallery-item */}
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4">
                  <div className="details">
                    <p className="location mb-0">
                      <i className="fa fa-map-marker" />
                      Dubai
                    </p>
                    <h4 className="title">{tourpack.tourName}</h4>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                  <div className="book-list-warp">
                    <p className="book-list-content">
                      Just booked! Get your spot before it's too late.
                    </p>
                    {login === "yes" && (
                      <>
                        <div className="tp-price-meta">
                          <p>Price</p>
                          <h2>
                            {tourpack.tourPrice} &nbsp;&nbsp;&nbsp;
                            <small>AED</small>
                          </h2>
                        </div>
                      </>
                    )}
                  </div>
                  <ul className="tp-list-meta border-tp-solid">
                    <li>
                      <i className="fa fa-clock-o" /> {tourpack.tourNofDays}{" "}
                      Days
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <Row>
            <Col sm={8}>
              <div className="tour-details-wrap">
                <h4 className="single-page-small-title">{tourpack.tourName}</h4>
                <p>
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tourpack.tourDescription,
                    }}
                  />
                </p>
              </div>

              <h4>Tour Day Plan</h4>

              {tourpack.packageDetail.map((packdetail) => (
                <>
                  <Card>
                    <CardBody key={packdetail.packageDetailId}>
                      <Row>
                        <Col xs={4}>
                          <img
                            src={`${
                              requests.imgpath + packdetail.packageSitePhoto
                            }`}
                            height="100px"
                            width="200px"
                            alt={packdetail.packageSitePhoto}
                          />
                        </Col>
                        <Col xs={8}>
                          <h3 className="title">
                            {packdetail.packageDayHeading}
                          </h3>

                          <p> {packdetail.packageDayActivity}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <br />
                  <br />
                </>
              ))}

              {/* {tourpack.packageDetail.map((packdetail) => (
                <div key={packdetail.packageDetailId}>
                  <Collapsible
                    trigger={
                      <Row>
                        <Col sm={1}>
                          <h3>Day {packdetail.packageDayHeading}</h3>
                        </Col>
                        <Col sm={11}>
                          {" "}
                          <img
                            src={publicUrl + "assets/img/icons/location.png"}
                            height='35px'
                            width='20px'
                          />
                        </Col>
                      </Row>
                    }>
                    <Row>
                      <Col sm={1}></Col>
                      <Col sm={7}>
                        <div className='vl'>
                          <div className='custom-margine'>
                            <p> {packdetail.packageDayActivity}</p>
                          </div>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <img
                          src={`${
                            requests.imgpath + packdetail.packageSitePhoto
                          }`}
                          height='100px'
                          width='200px'
                        />
                      </Col>
                    </Row>
                  </Collapsible>
                  <hr className='new1' />
                </div>
              ))} */}
            </Col>
            <Col sm={4}>
              <h3 className="single-page-small-title">Enquiry </h3>
              <Form onSubmit={handleSubmit(setTourenq.bind(this))}>
                <Field
                  name="attractionId"
                  type="hidden"
                  icon=""
                  component={RenderField.RenderTextField}
                />
                <Field
                  name="enqCusName"
                  type="text"
                  icon=""
                  label="Name"
                  component={RenderField.RenderTextField}
                />
                <Field
                  name="enqEmail"
                  type="email"
                  label="Email Address"
                  component={RenderField.RenderTextField}
                />
                <Field
                  name="enqMobileNumber"
                  type="number"
                  label="Contact Number"
                  component={RenderField.RenderTextField}
                />
                <Field
                  name="enqMessage"
                  type="textarea"
                  label="Message "
                  component={RenderField.RenderTextField}
                />

                <br />

                <div class="text-lg-center text-left">
                  <button
                    type="submit"
                    class="btn btn-yellow"
                    disabled={submitting}
                  >
                    Send Enquiry
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>

      <Subscribe />
      <Footer />
    </>
  );
};

export default reduxForm({
  form: "TourpackDetail",
  validate,
})(TourpackDayDetail);
