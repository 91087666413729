import { reduxForm, Field, Form } from "redux-form";
import { useState } from "react";
import RenderField from "../form-components/RenderField";
import axios from "axios";
import requests from "../utils/Requests";
import { Alert } from "reactstrap";

const Emailidforrecpass = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;

  const [serverRes, setserverRes] = useState();

  const [alertTime, setalertTime] = useState(0);

  const handleSubmiemail = (values, dispatch) => {
    sessionStorage.setItem("eMail", values.userName);
    // console.log(values);
    axios

      .post(requests.setotpforexistinguser, values)
      .then((res) => {
        console.log("otp", res.data);
        if (res.data.errCode == 75) {
          setserverRes("Invalid Email Id");
          setalertTime(0);
          setTimeout(function () {
            setalertTime(0);
            dispatch(reset());
          }, 5000);
        } else {
          if (res.data.b2b === true) {
            sessionStorage.setItem("user", "b2b");
          } else if (res.data.b2bUser === true) {
            sessionStorage.setItem("user", "b2bUser");
          } else if (res.data.b2c === true) {
            sessionStorage.setItem("user", "b2c");
          }

          props.otppage(120);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {alertTime == 1 ? (
        <>
          <Alert color="danger">{serverRes}</Alert>
        </>
      ) : null}
      <Form onSubmit={handleSubmit(handleSubmiemail.bind(this))}>
        <Field
          name="userName"
          type="email"
          icon=""
          component={RenderField.RenderTextField}
        />
        <br />

        <button type="submit" class="btn btn-yellow" disabled={submitting}>
          Send OTP{" "}
        </button>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "Emailidforrecpass",
})(Emailidforrecpass);
