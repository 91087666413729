import { Link } from "react-router-dom";
import requests from "../../utils/Requests";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Col } from "reactstrap";
const UpcommingPack = ({ attData, login }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div
        className="upcomming-tour upcomming-tour-bg pd-top-75 pd-bottom-120 viaje-go-top"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/11.png)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title">
                <h2 className="title">Upcoming Tours</h2>
                <p>
                  Travel should be like a book. When you’re done reading it, you
                  close the cover and remember the story.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <form className="search-form">
                    <div className="form-group">
                      <input type="text" placeholder="Search" />
                    </div>
                    <button className="submit-btn" type="submit">
                      <i className="ti-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="upcomming-card-slider upcomming-card-slider- tp-common-slider-style">
                <Carousel responsive={responsive}>
                  {attData.map((attraction, index) => (
                    <div key={index}>
                      <Link
                        to={`tour-pack-details/${attraction.attractionsId}`}
                      >
                        <div className="single-upconing-card">
                          <div
                            className="shadow"
                            style={{
                              backgroundImage:
                                requests.imgpath + attraction.attThumbnailImage,
                            }}
                          >
                            <img
                              src={
                                requests.imgpath + attraction.attThumbnailImage
                              }
                              alt="parmartours"
                              height="380px"
                            />
                          </div>{" "}
                          {isLoggedIn === "yes" && (
                            <div className="tp-price-meta tp-price-meta-cl">
                              <Row>
                                <Col>
                                  <p>
                                    Adult Price <h2>{attraction.adultPrice}</h2>
                                  </p>{" "}
                                  &nbsp;&nbsp;&nbsp;<small>AED</small>
                                </Col>
                                <Col>
                                  <p>
                                    Child Price <br />
                                    <h2>{attraction.childPrice}</h2>
                                  </p>{" "}
                                  &nbsp;&nbsp;&nbsp;<small>AED</small>
                                </Col>
                              </Row>
                            </div>
                          )}
                          <div className="details">
                            <p
                              className="title"
                              style={{
                                color: "white",
                              }}
                            >
                              {attraction.attName}
                            </p>
                            <p>
                              <i className="fa fa-map-marker" />{" "}
                              {attraction.attCity}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcommingPack;
