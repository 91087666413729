import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
import { Field, Form, reduxForm } from "redux-form";
import RenderField from "../../form-components/RenderField";
import encryptStorage from "../../utils/Encryptstorage";
import requests from "../../utils/Requests";
import Swal from "sweetalert2";
const validate = (values) => {
  const errors = {};
  if (!values.fName) {
    errors.fName = "Required";
  }
  if (!values.lName) {
    errors.lName = "Required";
  }
  if (!values.dob) {
    errors.dob = "Required";
  }
  if (!values.gender) {
    errors.gender = "Required";
  }
  if (!values.nationality) {
    errors.nationality = "Required";
  }
  if (!values.passportNumber) {
    errors.passportNumber = "Required";
  }
  if (!values.birthCity) {
    errors.birthCity = "Required";
  }
  if (!values.birthCountry) {
    errors.birthCountry = "Required";
  }
  if (!values.passportCountry) {
    errors.passportCountry = "Required";
  }

  if (!values.passportIssueDate) {
    errors.passportIssueDate = "Required";
  }

  if (!values.passportExpiryDate) {
    errors.passportExpiryDate = "Required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Required";
  }

  if (!values.contactNumber2) {
    errors.contactNumber2 = "Required";
  }
  if (!values.eMail) {
    errors.eMail = "Required";
  }

  return errors;
};

const VisaForm = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  let history = useHistory();

  // private int visaEnquiryId;
  // private int visaId;
  // private String firstName;
  // private String middleName;
  // private String lastName;
  // private String nationality;
  // private String passportNumber;
  // private Date dateOfBirth;
  // private String gender;
  // private Date passportIssueDate;
  // private Date passportExpiryDate;
  // private String birthPlace;
  // @Column(nullable = true)
  // private String birthCity;
  // private String birthCountry;
  // private String passportCountry;
  // private String city;
  // private String phoneNumber;
  // @Column(nullable = true)
  // private String alternateNumber;
  // @Column(nullable = true)
  // private String eMail;
  const [visaData, setvisaData] = useState([]);
  useEffect(() => {
    let visadata = encryptStorage.getItem("asiv");
    let visaObject = JSON.parse(visadata);
    // console.log("visa Data", visaObject);
    setvisaData(visaObject);
  }, []);
  const [passportFront, setpassportFront] = useState("");
  const [passportBack, setpassportBack] = useState("");
  const [userImage, setuserImage] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [uploadImg, setUploadImg] = useState(0);

  const genderData = [
    { name: "Gender", value: "" },
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
    { name: "Others", value: "Others" },
  ];

  const uploadImage = (e, name) => {
    setUploadImg(name);

    seterrorMsg("");
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("file", file);

    axios
      .post("https://parmartours.com:8443/parmartour/v1.0/fileupload", formdata)
      .then((res) => {
        // console.log("response", res.data); //fileName
        setUploadImg("");

        if (name == 1) {
          setpassportFront(res.data.fileName);
        } else if (name == 2) {
          setpassportBack(res.data.fileName);
        } else {
          setuserImage(res.data.fileName);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const sumbittoAPI = (values) => {
    seterrorMsg("");
    if (passportFront == "" || passportBack == "" || userImage == "") {
      if (passportFront == "") {
        seterrorMsg("Upload Image Properly");
      }
      if (passportBack == "") {
        seterrorMsg("Upload Image Properly");
      }
      if (userImage == "") {
        seterrorMsg("Upload Image Properly");
      }
    } else {
      const submitData = {
        firstName: values.fName,
        middleName: values.mName,
        lastName: values.lName,
        nationality: values.nationality,
        passportNumber: values.passportNumber,
        dateOfBirth: values.dob,
        gender: values.gender,
        passportIssueDate: values.passportIssueDate,
        passportExpiryDate: values.passportExpiryDate,
        birthCity: values.birthCity,
        birthCountry: values.birthCountry,
        passportCountry: values.passportCountry,
        phoneNumber: values.phoneNumber,
        alternateNumber: values.alternateNumber,
        eMail: values.eMail,
        visaType: visaData.name,
        withInsurance: visaData.insurance,
        visaPrice: visaData.price,
        visaPassportFrontImage: passportFront,
        visaPassportBackImage: passportBack,
        visaPhoto: userImage,
      };
      // console.log("Form Submit", submitData);    setVisaEnquiry
      axios
        .post(requests.setVisaEnquiry, submitData)
        .then((response) => {
          Swal.fire({
            title: "Thank You",
            text: "Your Enquiry Has Been Submited, Our Executive Will Call Very Soon",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              history.goBack();
            }
          });
        })
        .catch((errorMsg) => {
          console.log(errorMsg);
        });
    }
  };

  return (
    <>
      {" "}
      <Form onSubmit={handleSubmit(sumbittoAPI.bind(this))}>
        <Row>
          <Col xs={4}>
            <Field
              name="fName"
              type="text"
              icon=""
              label="First Name"
              component={RenderField.RenderTextField}
            />
          </Col>

          <Col xs={4}>
            <Field
              name="mName"
              type="text"
              icon=""
              label="Middle Name"
              component={RenderField.RenderTextField}
            />
          </Col>

          <Col xs={4}>
            <Field
              name="lName"
              type="text"
              icon=""
              label="Last Name"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              name="dob"
              type="date"
              icon=""
              label="Date Of Birth"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="gender"
              type="select"
              customfeild={genderData}
              label="Gender"
              component={RenderField.renderOptionField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="nationality"
              type="text"
              icon=""
              label="Nationality"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <Field
              name="passportNumber"
              type="text"
              icon=""
              label="Passport Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="birthCity"
              type="text"
              icon=""
              label="Birth City"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="birthCountry"
              type="text"
              icon=""
              label="Birth Country"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              name="passportCountry"
              type="text"
              icon=""
              label="Passport Issue Country"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="passportIssueDate"
              type="date"
              icon=""
              label="Passport Issue Date"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="passportExpiryDate"
              type="date"
              icon=""
              label="Passport Expiry Date"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <Field
              name="phoneNumber"
              type="text"
              icon=""
              label="Contact Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="contactNumber2"
              type="text"
              icon=""
              label="Additional Number"
              component={RenderField.RenderTextField}
            />
          </Col>
          <Col xs={4}>
            <Field
              name="eMail"
              type="email"
              icon=""
              label="E-Mail"
              component={RenderField.RenderTextField}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Passport Front Side
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 1)} />
            {uploadImg == 1 ? (
              <Alert color="primary">
                Please Wait <Spinner color="light" />
              </Alert>
            ) : null}

            {passportFront == "" ? null : (
              <img
                src={
                  "https://parmartours.com:8443/filestorage/parmartour/images/" +
                  passportFront
                }
                alt="parmartours"
              />
            )}
          </Col>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Passport Back Side
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 2)} />

            {uploadImg == 2 ? (
              <Alert color="primary">
                Please Wait <Spinner color="light" />
              </Alert>
            ) : null}

            {passportBack == "" ? null : (
              <img
                src={
                  "https://parmartours.com:8443/filestorage/parmartour/images/" +
                  passportBack
                }
                alt="parmartours"
              />
            )}
          </Col>
          <Col xs={4}>
            <div className="single-widget-search-input-title">
              Applicant Image
            </div>

            <input type="file" onChange={(e) => uploadImage(e, 3)} />

            {uploadImg == 3 ? (
              <Alert color="primary">
                Please Wait <Spinner color="light" />
              </Alert>
            ) : null}

            {userImage == "" ? null : (
              <img
                src={
                  "https://parmartours.com:8443/filestorage/parmartour/images/" +
                  userImage
                }
                alt="parmartours"
              />
            )}
          </Col>
        </Row>
        <br />

        {errorMsg == "" ? null : <Alert color="danger">{errorMsg}</Alert>}

        <div className="text-lg-center text-left">
          <Button
            color="primary"
            type="submit"
            class="btn btn-yellow"
            disabled={submitting}
          >
            Book Now
          </Button>
        </div>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "VisaBookingform",
  validate,
})(VisaForm);
