import React, { useEffect } from "react";
import { Field, Form, reduxForm, reset } from "redux-form";
import RenderField from "../../form-components/RenderField";
import requests from "../../utils/Requests";
import axios from "axios";
import Swal from "sweetalert2";
const AfterSubmit = (result, dispatch) => {
  dispatch(reset("Contact"));
  //window.location.href = "/success";
};
const validate = (values) => {
  const errors = {};
  if (!values.enqCusName) {
    errors.enqCusName = "Required ";
  }
  if (!values.enqEmail) {
    errors.enqEmail = "Required ";
  }
  if (!values.enqMobileNumber) {
    errors.enqMobileNumber = "Required";
  }
  if (!values.enqMessage) {
    errors.enqMessage = "Required";
  }

  return errors;
};

let publicUrl = process.env.PUBLIC_URL + "/";
const Contact = (props) => {
  //postEnquiry

  // private int enquiryId;
  // private int tourPackageId;
  // private String tourPackageName;
  // private String enqCusName;
  // private String enqEmail;
  // private String enqMobileNumber;
  // private String enqMessage;
  // private Date enquiryDate;
  // private String enqFollowUpStatus;

  const userEnquiry = (values, dispatch) => {
    let currentdate = new Date();
    const enquiryData = {
      enquiryId: "",
      tourPackageId: 0,
      tourPackageName: " ",
      enqCusName: values.enqCusName,
      enqEmail: values.enqEmail,
      enqMobileNumber: values.enqMobileNumber,
      enqMessage: values.enqMessage,
      enquiryDate: currentdate,
      enqFollowUpStatus: "",
    };

    // console.log(enquiryData);
    axios
      .post(requests.setenquiry, enquiryData)
      .then((res) => {
        // console.log(res);
        // PT20210938074.pdf
        // window.alert(
        //   "Thank You For Your Interest,  Our Executive Will Contact Soon"
        // );
        Swal.fire({
          title: "Dear Customer", //'Good job!',
          text: "Our Agent Will Contact You Shortly", //'You clicked the button.',
          icon: "success", //'success'
        });
        dispatch(props.reset());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <div>
      <div className="contact-area pd-top-108">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-lg-center text-left">
                <h2 className="title">Get In Touch!</h2>
                <p>
                  Travel should be like a book. When you’re done reading it, you
                  close the cover and remember the story, Travel is my love
                  language. Departures, arrivals, hotels, and getting lost ― We
                  love it all.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <div className="thumb">
                <img
                  src={
                    publicUrl +
                    "assets/img/others/woman-working-call-center.jpg"
                  }
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <Form onSubmit={handleSubmit(userEnquiry.bind(this))}>
                <div className="row">
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Name</span>
                      <Field
                        name="enqCusName"
                        type="text"
                        component={RenderField.RenderTextField}
                      />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Number</span>
                      <Field
                        name="enqMobileNumber"
                        type="text"
                        component={RenderField.RenderTextField}
                      />
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Email</span>
                      <Field
                        name="enqEmail"
                        type="email"
                        component={RenderField.RenderTextField}
                      />
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Message</span>
                      <Field
                        name="enqMessage"
                        type="text"
                        component={RenderField.RenderTextField}
                      />
                    </label>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      class="btn btn-yellow"
                      disabled={submitting}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-info-area pd-top-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 order-lg-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d902.070362243873!2d55.29452222938783!3d25.26111597409959!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433fc9409919%3A0xf78752b41229b055!2sParmar%20Tourism%20LLC!5e0!3m2!1sen!2sin!4v1631808171513!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
              />
            </div>
            <div className="col-xl-5 col-lg-6 order-lg-1">
              <div className="contact-info bg-gray">
                <p>
                  <i className="fa fa-map-marker" />
                  <span>
                    201 API BUILDING, TIA sarees Same building - Bur Dubai - Al
                    Souq Al Kabeer - Dubai - United Arab Emirate
                  </span>
                </p>
                {/* <p>
                    <i className="fa fa-clock-o" />
                    <span>Office Hour 9:00 to 7:00 Sunday 10:00 to 5:00</span>
                  </p> */}
                <p>
                  <i className="fa fa-envelope" />
                  <span>
                    Email:{" "}
                    <span>
                      {" "}
                      parmar@parmartours.com <br />
                      visa@parmartours.com <br />
                      reservation@parmartours.com <br />
                      sales@parmartours.com{" "}
                    </span>
                  </span>
                </p>
                <p>
                  <span>
                    <i className="fa fa-phone" /> <span>+971586559322</span>
                    <br />
                    <i className="fa fa-phone" />
                    <span>+971524327298</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "Contact",
  validate,
  onSubmitSuccess: AfterSubmit,
})(Contact);
