import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const test = "production";
if (test === "production") {
  console.error = () => {};
  console.log = () => {};
  console.warn = () => {};
  console.debug = () => {};
}
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("parmar")
);

// password
// :
// "PT@dubai2022"
// userName
// :
// "parmartourismllc@gmail.com"
