import React, { useEffect, useState } from "react";
import TopNav from "./top_nav";
import Footer from "./footer";
import CommonBanner from "./common_banner";
import { useParams } from "react-router";
import axios from "axios";
import ticketCounter from "./ticket-counter.jpg";
const GenerateTickets = () => {
  const { id } = useParams();
  const [isLoaded, setisLoaded] = useState(false);
  useEffect(() => {
    const getTickets = () => {
      axios
        .post(
          "https://b2b.parmartours.com:8443/parmartour/v1.0/getGenerateTicketPdf",
          { bookingId: id }
        )
        .then((res) => {
          const fileURL =
            // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
            "https://b2b.parmartours.com/filestorage/" +
            res.data.bookingTickPdfPath;
          const filename = "e-Ticket.pdf";
          download_file(fileURL, filename);
          setisLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getTickets();
  }, [id]);
  function download_file(fileURL, fileName) {
    // for non-IE

    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  return (
    <div>
      <TopNav />
      <CommonBanner imgUrl="ticket-counter.jpg" />
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <div className="row justify-content-center">
            {isLoaded === false ? (
              <h2>Please Wait</h2>
            ) : (
              <h2>Your Ticket Has Been Downloaded</h2>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GenerateTickets;
