import Packdetail from "../tour-pack-detail/pack_details";

const TourPackDetail = (props) => {
  // const { handleSubmit,  pristine, reset, submitting } = props;
  return (
    <>
      <Packdetail />
    </>
  );
};

export default TourPackDetail;
