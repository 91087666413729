import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";
import About from "./section-components/about_us";
const AboutUs = () => {
  return (
    <>
    <TopNav/>
      <CommonBanner title="About Us"  imgUrl ="aboutUs.jpeg"/>
    <About/>
    <Subscribe/>
    <Footer/>
    </>
  );
};

export default AboutUs;
