import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
import requests from "../../utils/Requests";
import axios from "axios";
import encryptStorage from "../../utils/Encryptstorage";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import VisaData from "./VisaData";
import AbudabiVisaData from "./AbidabiVisaData";
const Visacommon = () => {
  let history = useHistory();
  let publicUrl = process.env.PUBLIC_URL + "/assets/img/visa/";
  const [visaList, setvisaList] = useState([]);
  const [dubaiVisa, setdubaiVisa] = useState("");
  const [abudabiVisa, setabudabiVisa] = useState("");

  const actionListener = (name, price, insurance) => {
    // console.log("Visa data", name, "visa price", price);
    const visaBook = {
      name: name,
      price: price,
    };
    // console.log("visaBook ", visaBook);
    encryptStorage.setItem("asiv", visaBook);
    // const test = encryptStorage.getItem("asiv"); //obj
    // let visaObj = JSON.parse(test);
    // console.log("VIsa ", visaObj);
    history.push("/visa-booking-service");

    // let values = [...visaList];

    // values.push({
    //   name: name,
    //   price: price,
    //   //visaCount: visaCount,
    // });

    // setvisaList(values);
    // // setvisaCount(1);
  };

  // const deleteRow = (val) => {
  //   const visafilter = visaList.filter((visa) => visa.name !== val);
  //   setvisaList(visafilter);
  // };

  useEffect(() => {
    getVisa();
  }, []);

  const [Visa, setVisa] = useState([]);

  const getVisa = () => {
    axios
      .post(requests.getVisaList, { visaId: 0 })
      .then((response) => {
        setVisa(response.data);
        const tempData = response.data;

        let dVisa = [],
          abVisa = [];
        for (let i = 0; i < tempData.length; i++) {
          console.log(i);
          if (tempData[i].country == "Dubai") {
            dVisa.push({
              country: tempData[i].country,
              expressCost: tempData[i].expressCost,
              imageFile: tempData[i].imageFile,
              visaDuration: tempData[i].visaDuration,
              visaId: tempData[i].visaId,
              visaName: tempData[i].visaName,
              visaPrice: tempData[i].visaPrice,
              visaShortDescription: tempData[i].visaShortDescription,
            });
          }
          if (tempData[i].country == "Abudabi") {
            abVisa.push({
              country: tempData[i].country,
              expressCost: tempData[i].expressCost,
              imageFile: tempData[i].imageFile,
              visaDuration: tempData[i].visaDuration,
              visaId: tempData[i].visaId,
              visaName: tempData[i].visaName,
              visaPrice: tempData[i].visaPrice,
              visaShortDescription: tempData[i].visaShortDescription,
            });
          }
        }
        setdubaiVisa(dVisa);
        setabudabiVisa(abVisa);
      })
      .catch((err) => {});
  };

  const submitData = () => {
    encryptStorage.setItem("asiv", visaList);
    const test = encryptStorage.getItem("asiv"); //obj

    let visaObj = JSON.parse(test);
    // console.log(visaObj);
    history.push("/visa-booking-service");
  };

  const visaEnq = () => {
    Swal.fire({
      title: "Thank You!",
      text: "Our Executive will contact soon",
      icon: "success",
    });
    setvisaList("");
  };
  return (
    <>
      <div className="tour-list-area pd-top-120 viaje-go-top">
        <div className="container">
          <h2 className="title">Dubai Visa</h2>
          <div className="row  ">
            {dubaiVisa &&
              dubaiVisa.map((visa) => (
                <div className="col-lg-3 col-sm-6" key={visa.visaId}>
                  <Card>
                    <CardImg
                      top
                      width="300"
                      height="200"
                      src={requests.imgpath + visa.imageFile}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <div className="justify-content-center">
                        <CardTitle>{visa.visaName}</CardTitle>
                        {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                        <CardText>
                          <Row>
                            <Col>
                              <p>Price</p>
                            </Col>
                            <Col>{visa.visaPrice + "  AED"}</Col>
                          </Row>
                        </CardText>
                        <Button
                          color={"success"}
                          onClick={() =>
                            actionListener(visa.visaName, visa.visaPrice)
                          }
                        >
                          Apply Now
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
          </div>

          <br />
          <h2 className="title">Abudabi Visa </h2>
          <div className="row  ">
            {abudabiVisa &&
              abudabiVisa.map((visa) => (
                <div className="col-lg-3 col-sm-6" key={visa.visaId}>
                  <Card>
                    <CardImg
                      top
                      width="300"
                      height="200"
                      src={requests.imgpath + visa.imageFile}
                      alt="Card image cap"
                    />
                    <CardBody>
                      <div className="justify-content-center">
                        <CardTitle>{visa.visaName}</CardTitle>
                        {/* <CardSubtitle>Card subtitle</CardSubtitle> */}
                        <CardText>
                          <Row>
                            <Col>
                              <p>Price</p>
                            </Col>
                            <Col>{visa.visaPrice + "  AED"}</Col>
                          </Row>
                        </CardText>
                        <Button
                          color={"success"}
                          onClick={() =>
                            actionListener(visa.visaName, visa.visaPrice)
                          }
                        >
                          Apply Now
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
          </div>

          {/* <Row>
            <Col xs={8}>
              <h2 className="title">Dubai Visa</h2>
              {localVisaData.map((visa) => (
                <div
                  className="single-destinations-list style-three"
                  key={visa.id}
                >
                  <Row>
                    <Col xs={2}>
                      <div className="tp-price-meta d-inline-block">
                        <svg>
                          <ellipse cx="50" cy="50" rx="50" ry="50">
                            {" "}
                            spfdksfdps
                          </ellipse>
                        </svg>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div className="tp-price-meta d-inline-block">
                        <h6>{visa.visaType}</h6>
                        <p>
                          <i class="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                          &nbsp;&nbsp; Consultant fees &nbsp;&nbsp;
                          <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                          &nbsp;&nbsp; Service Charges
                        </p>
                        50.00/- Express cost (Select Express Cost to Fast
                        Process)
                      </div>
                      <Row>
                        <Col>Number Of Visa</Col>
                        <Col>
                          <FormGroup>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              onChange={(e) => setvisaCount(e.target.value)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={3}>
                      <div className="tp-price-meta d-inline-block">
                        <div className="flexbuttons">
                        
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => actionListener(visa.visaType)}
                          >
                            Add
                          </Button>{" "}
                       
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}

              <h2 className="title">Abudabi Visa </h2>
              {localAbudabiVisaData.map((visa) => (
                <div
                  className="single-destinations-list style-three"
                  key={visa.id}
                >
                  <Row>
                    <Col xs={2}>
                      <div className="tp-price-meta d-inline-block">
                        <svg>
                          <ellipse cx="50" cy="50" rx="50" ry="50">
                            {" "}
                            spfdksfdps
                          </ellipse>
                        </svg>
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div className="tp-price-meta d-inline-block">
                        <h6>{visa.visaType}</h6>
                        <p>
                          <i class="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                          &nbsp;&nbsp; Consultant fees &nbsp;&nbsp;
                          <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                          &nbsp;&nbsp; Service Charges
                        </p>
                        50.00/- Express cost (Select Express Cost to Fast
                        Process)
                      </div>
                      <Row>
                        <Col>Number Of Visa</Col>
                        <Col>
                          <FormGroup>
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              onChange={(e) => setvisaCount(e.target.value)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={3}>
                      <div className="tp-price-meta d-inline-block">
                        <div className="flexbuttons">
                         
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => actionListener(visa.visaType)}
                          >
                            Add
                          </Button>{" "}
                        
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>
            <Col xs={4}>
              <Card>
                <CardBody>
                  <p>Check List</p>
                  {visaList == "" ? null : (
                    <>
                      {visaList.map((visa, i) => (
                        <div key={i}>
                          <Row>
                            <Col xs={6}>
                              <p>{visa.name}</p>
                            </Col>
                            <Col xs={3}>
                              <p>{visa.visaCount}</p>
                            </Col>
                            <Col xs={1}>
                              <i
                                class="fa fa-window-close"
                                aria-hidden="true"
                                onClick={() => deleteRow(visa.name)}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => visaEnq()}
                      >
                        Enquiry
                      </Button>{" "}
                      &nbsp; &nbsp; &nbsp;
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => submitData()}
                      >
                        Book Now
                      </Button>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
};

export default Visacommon;
