import { Row, Col, Alert } from "reactstrap";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import encryptStorage from "../../utils/Encryptstorage";
import axios from "axios";
import requests from "../../utils/Requests";
import Swal from "sweetalert2";
import { usData } from "../../utils/RdData";
const ApiTicketConfirmation = (props) => {
  let attname;

  const xmlResponce = usData();

  const hConfig = {
    headers: {
      "access-Key": xmlResponce,
    },
  };

  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    // console.log("clear");
    window.location.href = "/";
  };
  const [bookdetails, setbookdetails] = useState([]);
  const [paymentPortel, setpaymentPortel] = useState("");

  const [paymentTransactionId, setpaymentTransactionId] = useState("");
  const [isloading, setisloading] = useState(true);
  const [urlObj, seturlObj] = useState();
  const [bookingObj, setbookingObj] = useState();

  const [existObject, setexistObject] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    setTimeout(function () {
      const url = encryptStorage.getItem("url"); //obj
      let urlObject = JSON.parse(url);

      const preObj = encryptStorage.getItem("firstSubmitForBooking"); //obj
      let existObj = JSON.parse(preObj);

      console.log("existObj", existObj);
      setexistObject(existObj);
      seturlObj(urlObject);
      setpaymentTransactionId(urlObject.paymentTransactionId);
      setpaymentPortel(urlObject.paymentUrl);

      const obj = encryptStorage.getItem("DFuck");
      let bookingObject = JSON.parse(obj);
      setbookingObj(bookingObject);
      // console.log("BookingObject", bookingObject);
      setbookdetails(bookingObject);

      // console.log("Url Object", urlObject);
      // console.log("paymentPortel", paymentPortel);
      // console.log("paymentTransactionId", paymentTransactionId);
      setisloading(false);
    }, 1000);

    // console.log("sdsfsfsfsfsfs", urlObject);
    // console.log("sdsfsfsdfdfdfd", bookingObject);
  }, []);

  attname = sessionStorage.getItem("attname");

  //paymentUrl, paymentTransactionId

  let history = useHistory();

  const [errMessage, seterrMessage] = useState("");
  const [showErrMsg, setshowErrMsg] = useState(false);
  const [alertStyle, setalertStyle] = useState("");

  const bookBurjTicket = () => {
    setalertStyle("primary");
    seterrMessage("Please Wait...");
    setshowErrMsg(true);

    const bookTicket = {
      platformId: 1,
      bookCompanyReference: urlObj.bookCompanyReference,
      attractionId: urlObj.attractionId,
      agencyId: urlObj.agencyId,
      agencyUserId: urlObj.agencyUserId,
      bookB2cId: urlObj.bookB2cId,
      customerName: urlObj.customerName,
      customerEmail: urlObj.customerEmail,
      customerMobileNumber: bookingObj.bookMobileNumber,
      bookingDate: urlObj.bookingDate,
      eventId: urlObj.eventId,
      eventName: urlObj.eventName,
      eventTypeId: urlObj.eventTypeId,
      resourceId: urlObj.resourceId,
      startDateTime: urlObj.startDateTime,
      endDateTime: urlObj.endDateTime,
      available: urlObj.available,
      priceRefId: urlObj.priceRefId,
      status: urlObj.status,
      nofAdult: urlObj.nofAdult,
      nofChild: urlObj.nofChild,
      nofInfant: urlObj.nofInfant,
      adultPrice: urlObj.adultPrice,
      childPrice: urlObj.childPrice,
      bookingId: urlObj.bookingId,
      invoiceNo: urlObj.invoiceNo,
      totalPrice: urlObj.totalPrice,
      bookPaymentMode: bookingObj.bookPaymentMode,
      bookPaymentRefId: urlObj.bookPaymentRefId,
      parmarTicketTypeId: bookingObj.ticketTypeId,
      ticketTypeId: bookingObj.ticketTypeId,
      bookingAddon: bookingObj.bookingAddon,
      secretKey: requests.apiKey,
      bookedByBackOffice: urlObj.bookedByBackOffice,
      bookedCreditLimit: urlObj.bookedCreditLimit,
      superAdminId: urlObj.superAdminId,
      errorMessage: urlObj.splError,
      agentRefNumber: bookingObj.agentRefNumber,

      //   customerName: agencyId.customerName,
      //   bookingId: urlObj.bookingId,
    };
    console.log(`${JSON.stringify(bookTicket, null, 2)}`);

    if (bookingObj.bookPaymentMode == 1) {
      const paymentData = {
        platformId: 1,
        paymentAgencyId: urlObj.agencyId,
        paymentAgencyUserId: urlObj.agencyUserId,
        paymentB2cId: urlObj.bookB2cId,
        paymentCustomerName: urlObj.customerName,
        paymentRemarks: "Test Pay",
        paymentAmount: urlObj.totalPrice,
        secretKey: requests.apiKey,
        successUrl: `https://b2b.parmartours.com/${"datedTicket"}`,
        failureUrl: `https://b2b.parmartours.com/${"datedTicket"}`,
        tempBurjKhalifaBookingDto: bookTicket,
      };

      axios
        .post(requests.setStripeBurjKhalifaPaymentsDetail, paymentData)
        .then((res) => {
          console.log(res.data);
          if (res.data.paymentUrl != "") {
            window.location.replace(res.data.paymentUrl);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      axios
        .post(
          requests.confirmBurjTicket,
          // "http://66.29.149.191:8080/parmartour/v1.0/confirmBurjTicket",
          bookTicket,
          hConfig
        )
        .then((response) => {
          console.log("Responce data", response.data);

          console.log(`${JSON.stringify(response.data, null, 2)}`);
          if (response.data.errCode == 700) {
            setalertStyle("danger");
            seterrMessage(
              response.data.errCode + " - " + response.data.errMessage
            );
            setshowErrMsg(true);
          } else if (response.data.errCode == 100) {
            // console.log("Insufficiend balance")
            setalertStyle("danger");
            seterrMessage(
              response.data.errCode + " - " + response.data.errMessage
            );
            setshowErrMsg(true);
          } else if (response.data.errCode == 222) {
            setalertStyle("danger");
            seterrMessage(
              response.data.errCode + " - " + response.data.errMessage
            );
            setshowErrMsg(true);
          } else if (response.data.errorCode == 563) {
            Swal.fire({
              title: "Warning",
              text: "Another User has logged in using your Login Credentials, Please Login Again.",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Ok",
              denyButtonText: `Don't save`,
            }).then((result) => {
              if (result.isConfirmed) {
                // download_file(fileURL, filename);
                // setshowAlert(0);
                logout();
                //window.location.replace(fileURL)
              }
            });
          } else if (
            response.data.errCode == 0 ||
            response.data.errCode == 200
          ) {
            const fileURL =
              // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
              // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
              "https://b2b.parmartours.com/filestorage/" +
              response.data.ticketFilePath;
            const filename = response.data.ticketFilePath;

            Swal.fire({
              title: "Booking Confirmed",
              text: "Thank You For Your Booking, press OK to download your e-Ticket",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "Ok",
              denyButtonText: `Don't save`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                download_file(fileURL, filename);
              }
            });

            setshowErrMsg(false);
          } else {
            setshowErrMsg(true);
            setalertStyle("danger");
            seterrMessage(
              response.data.errCode + " - " + response.data.errMessage
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setshowErrMsg(true);
          setalertStyle("danger");
          seterrMessage(
           "Exception From Sever"
          );
        });
    }
  };

  function download_file(fileURL, fileName) {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
    history.goBack();
  }
  return (
    <>
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div className="container rounded bg-white">
            <div className="d-flex justify-content-end">
              <a class="cancel com-color" onClick={history.goBack}>
                Cancel and return to website
              </a>
            </div>
            <div className="d-flex justify-content-center">
              <h4 className="single-page-small-title">Your Booking Details</h4>
            </div>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Park Name </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? <p>Please Wait</p> : attname}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    existObject?.ticketname
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Booking Customer Name </h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerName
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Your Email</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookCustomerEmail
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Contact</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookMobileNumber
                  )}
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Ticket Type</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">{ctkttype}</div>
              </Col>
            </Row> */}
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Adult</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofAdult
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Number Of Child</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {bookdetails.bookNofChild == null ? (
                    0
                  ) : isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookNofChild
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Date</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTravellDate
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Time</h6>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    urlObj.startDateTime
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <h6>Total Amount</h6>
                </div>
              </Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  {isloading == true ? (
                    <p>Please Wait</p>
                  ) : (
                    bookdetails.bookTotal
                  )}
                </div>
              </Col>
            </Row>
            <br />
            {showErrMsg && <Alert color={alertStyle}>{errMessage}</Alert>}

            <Row>
              <Col xs={6}></Col>

              <Col xs={6}>
                <div className="d-flex justify-content-start">
                  <div class="buttons">
                    {" "}
                    {isloading == true ? null : (
                      <button
                        class="btn btn-success2 btn-block "
                        type="submit"
                        onClick={bookBurjTicket}
                      >
                        Proceed To Book
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>

      {/* <button onClick={()=>testPaymentform()}>Test Payment</button> */}
    </>
  );
};

export default ApiTicketConfirmation;
