import { Button, Col, Row } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import requests from "../../utils/Requests";
import encryptStorage from "../../utils/Encryptstorage";
import Bookinglist from "./BookingList";
import RenderField from "../../form-components/RenderField";
import MakePayment from "./MakePaymet";
import CreateSubUser from "./CreateSubUser";
import SubUserList from "./SubUserList";
import PaymentHistory from "./PaymentHistory";

const UserProfileComp = () => {
  const [cusObj, setcusObj] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [loginUser, setloginUser] = useState(0);
  const [LoggedIn, setLoggedIn] = useState(0);
  const [idForReport, setidForReport] = useState(0);
  const [loadingStatus, setloadingStatus] = useState(true);
  const [customerName, setCustomerName] = useState("");
  let userid;
  useEffect(() => {
    let login = sessionStorage.getItem("isLoggedIn");
    let cuname;
    if (login == "yes") {
      const enl = encryptStorage.getItem("enl"); //obj //agency.agencyId
      let urlObject = JSON.parse(enl);
      let tempObj = urlObject;
      setcusObj(tempObj);

      // console.log("cusObj", urlObject);
      if (urlObject.b2b == true) {
        let agency = urlObject.agency.agencyId; //agencyName
        getUser(agency);
        setidForReport(agency);
        setloginUser(1);
        setLoggedIn(1);
        setloadingStatus(true);
        cuname = urlObject.agency.agencyName;
        setCustomerName(cuname);
        setisloading(false);
      } else if (urlObject.b2bUser == true) {
        let agencyuser = urlObject.agencyUser.agencyUserId;
        let agencyidforgetdetail = urlObject.agencyUser.agencyId;
        getUser(agencyidforgetdetail);
        setidForReport(agencyidforgetdetail);
        setLoggedIn(1);
        setloginUser(2);
        setloadingStatus(true);
        cuname = urlObject.agencyUser.userFirstName;
        setCustomerName(cuname);
        setisloading(false);
      }
    } else {
    }

    let userName = sessionStorage.getItem("userName");
  }, []);

  const [agentDetails, setagentDetails] = useState([]);

  const getUser = async (val) => {
    // let agId = 0,
    //   aguId = 0;
    // if (loginUser == 1) {
    //   console.log("111111");
    //   agId = val;
    // } else if (loginUser == 2) {
    //   console.log("222222222");
    //   aguId = val;
    // }

    // const submitData = {
    //   agencyUserId: aguId,
    //   agencyId: agId,
    // };

    await axios
      .post(requests.getagencydetails, {
        agencyId: val,
        secretKey: requests.apiKey,
      })
      .then((res) => {
        setagentDetails(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let balace = agentDetails.creditLimit - agentDetails.creditUtilized;
  const logout = (e) => {
    sessionStorage.clear();
    window.location.reload();
    window.location.href = "/";
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [shoeTable, setshoeTable] = useState(1);

  const [mkPay, setmkPay] = useState(false);
  return (
    <div className="user-profile-area pd-top-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 col-lg-12">
            <div className="row">
              <div className="col-lg-4">
                <ul className="nav nav-tabs tp-tabs style-two">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs_1"
                      onClick={() => setshoeTable(1)}
                    >
                      <i className="fa fa-user" />
                      Profile
                    </a>
                  </li>

                  {LoggedIn == 1 ? (
                    <>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tabs_2"
                          onClick={() => setshoeTable(2)}
                        >
                          <i className="fa fa-check-square-o" />
                          Booking List
                        </a>
                      </li>
                    </>
                  ) : null}

                  {LoggedIn == 1 ? (
                    <>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tabs_4"
                          onClick={() => setshoeTable(3)}
                        >
                          <i className="fa fa-cog" />
                          Account Details
                        </a>
                      </li>
                    </>
                  ) : null}
                  {loginUser == 1 && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs_3"
                        onClick={() => setshoeTable(4)}
                      >
                        <i className="fa fa-cog" />
                        Create User
                      </a>
                    </li>
                  )}
                  {loginUser == 1 && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs_6"
                        onClick={() => setshoeTable(5)}
                      >
                        <i className="fa fa-cog" />
                        User List
                      </a>
                    </li>
                  )}

                  {loginUser == 1 && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        onClick={() => setshoeTable(6)}
                      >
                        <i className="fa fa-cog" />
                        Transaction History
                      </a>
                    </li>
                  )}

                  <li className="text-center">
                    {/* <button
                      type="submit"
                      class="btn btn-yellow"
                      onClick={(e) => logout(e)}
                    >
                      Log Out
                    </button> */}
                  </li>
                </ul>
              </div>
              <div className="col-xl-7 col-lg-8 offset-xl-1">
                <div className="tab-content user-tab-content">
                  <div className="tab-pane fade show active" id="tabs_1">
                    {shoeTable == 1 ? (
                      <>
                        <div className="user-details">
                          <h3 className="user-details-title">Profile</h3>
                          <div className="tp-img-upload">
                            <div className="tp-avatar-preview">
                              <div
                                id="tp_imagePreview"
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    publicUrl +
                                    "assets/img/team/1.png)",
                                }}
                              ></div>
                            </div>
                            <div className="tp-avatar-edit">
                              <input
                                type="file"
                                id="tp_imageUpload"
                                accept=".png, .jpg, .jpeg"
                              />
                              <label
                                className="btn btn-transparent"
                                htmlFor="tp_imageUpload"
                              >
                                <i className="fa fa-picture-o" />
                                Change Photo
                              </label>
                              <h4 className="name">
                                {loginUser == 1 ? <></> : <></>}
                                {customerName}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="tab-pane fade" id="tabs_3">
                    <CreateSubUser agentId={idForReport} />
                  </div>
                  <div className="tab-pane fade" id="tabs_6">
                    <SubUserList agentId={idForReport} />
                  </div>
                  <div className="tab-pane fade" id="tabs_4">
                    {shoeTable == 3 ? (
                      <>
                        <h3 className="user-details-title">
                          Your Account Summary
                        </h3>
                        <Row>
                          <Col>
                            <h4 className="name">Credit Limit</h4>
                          </Col>
                          <Col>
                            <h4 className="name">{agentDetails.creditLimit}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h4 className="name">Utilized Amount</h4>
                          </Col>
                          <Col>
                            <h4 className="name">
                              {agentDetails.creditUtilized}
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <h4 className="name">Balance Amount</h4>
                          </Col>
                          <Col>
                            <h4 className="name">
                              {agentDetails.currentBalance}
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          {mkPay == false ? (
                            <Button
                              color="primary"
                              onClick={() => setmkPay(true)}
                            >
                              Pay{" "}
                            </Button>
                          ) : (
                            <Button
                              color="danger"
                              onClick={() => setmkPay(false)}
                            >
                              Cancel
                            </Button>
                          )}
                          &nbsp; &nbsp; &nbsp;
                          {mkPay == true ? (
                            <>
                              <MakePayment idForReport={idForReport} />
                            </>
                          ) : null}
                        </Row>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {LoggedIn == 1 ? (
          <>
            {" "}
            {loadingStatus == true ? (
              shoeTable == 2 ? (
                <Bookinglist loginUser={loginUser} idForReport={idForReport} />
              ) : null
            ) : null}
          </>
        ) : null}
        {LoggedIn == 1 ? (
          <>
            {" "}
            {loadingStatus == true ? (
              shoeTable == 6 ? (
                <PaymentHistory agentId={idForReport} />
              ) : null
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default UserProfileComp;
