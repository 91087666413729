import React from 'react';
import TestBookingForm from './TestBookingForm';
const TestReduxForm = () =>{
return(
<>

<TestBookingForm/>
</>
);
}
export default TestReduxForm;