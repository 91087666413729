const RouteMenu = [
    {
        name: "Home",
        fixMenu: true,
        path: "/",
        SubMemu: []

    },
    {
        name: "About Us",
        fixMenu: true,
        path: "/about-us",
        SubMemu: []

    },
    {
        name: "Attraction",
        fixMenu: true,
        path: "/attraction",
        SubMemu: []

    },
    {
        name: "Combo-Offer",
        fixMenu: true,
        path: "/combo-pack",
        SubMemu: []

    },

    {
        name: "Tour Packages",
         fixMenu: true,
        path: "/tour-packages",
        // SubMemu: [
        //     {
        //         name: "Holiday",
        //         path: "/pakages/holiday"
        //     },
        //     {
        //         name: "Honey Moon",
        //         path: "/pakages/honeymoon"
        //     },
        // ]

    },
    {
        name: "Visa" ,
        fixMenu: true,
        path: "/visa-services"
    
    },
// {
//     name: "EXPO 2020" ,
//     fixMenu: true,
//     path: "/dubai-expo-2020"

// },

    {
        name: "Contact",
        fixMenu: true,
        path: "/contact-page"
    },
    // {
    //     name: "TestPayment",
    //     fixMenu: true,
    //     path: "/Test-payment"
    // },

]
export default RouteMenu;