import CommonBanner from "../global-components/common_banner";
import Footer from "../global-components/footer";
import Subscribe from "../global-components/subscribe";
import TopNav from "../global-components/top_nav";
import CoundownTimer from "./CoundownTimer";
import Expobanner from "./expo-banner";
import Expocontent from "./Expo-Content";

const Expopage = () => {
    return ( <>
 <TopNav/>
 {/* <CommonBanner title="Dubai Expo 2020"/> */}
<CoundownTimer/>

<Expocontent/>
 <Subscribe/>
 <Footer/>
    </> );
}
 
export default Expopage;