import Contact from "./blog-components/contact";
import CommonBanner from "./global-components/common_banner";
import Footer from "./global-components/footer";
import Subscribe from "./global-components/subscribe";
import TopNav from "./global-components/top_nav";
const Contactpage = () => {
  return (
    <>
      <TopNav />
      <CommonBanner title="Contact" imgUrl="Contact-us.jpg" />
      <Contact />
      <Subscribe />
      <Footer />
    </>
  );
};

export default Contactpage;
